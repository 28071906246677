<template>
  <div
    class="fixed bottom-0 left-0 m-10 mb-0 ml-0 float-left text-white z-50"
  >
    <a
      href="#topId"
      class="button-top font-medium text-xs leading-tight uppercase hover:bg-red-700 focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-600 active:shadow-lg active:text-white duration-100 ease-in-out flex items-center transition-all w-10 h-10 md:w-14 md:h-14 hover:shadow-sm shadow hover:w-24 bg-red-900"
      type="button"
    >
      <span class="top-visible">Topo</span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-10 h-10 text-center m-2 hover:ml-2 md:ml-2"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.bottom-36 {
  bottom: 9.5rem /* 144px */;
}

.top-visible {
  display: none;
  margin-left: 10px;
}

.button-top:hover .top-visible {
  display: inline;
}
</style>
