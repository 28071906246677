<template>
  <div id="conteudo-1" class="lg:col-span-2 text-left text-gray-500">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
