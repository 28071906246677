<template>
  <h1
    class="font-h1 font-bold rounded-t px-3 py-4 text-center lg:text-left bg-image-menu text-white uppercase"
  >
    <slot></slot>
  </h1>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-image-menu {
  background: url("../../assets/pattern-dark.png") !important;
}

.contrast .bg-image-menu {
  background: rgba(255, 255, 255, 0.2) !important;
}

.contrastNegative .bg-image-menu {
  background: rgba(255, 255, 255, 0.2) !important;
}
</style>
