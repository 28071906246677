<template>
  <div>
    <header-comp />
    <Transition name="slide-fade">
      <router-view class="mx-auto" />
    </Transition>
    <float-button />
    <acess-comp />
    <vlibras-comp />
    <footer-comp />
  </div>
</template>

<script>
/* Tem 2 headers pois o segundo é o que estou (Elias) fazendo todas as secretarias, subsecretarias, entre outros */
import HeaderComp from "@/components/HeaderComp.vue";
import FooterComp from "@/components/FooterComp.vue";
import AcessComp from "@/components/AcessComp.vue";
import FloatButton from "@/components/float-button.vue";
import VlibrasComp from "@/components/VlibrasComp.vue";

export default {
  name: "App",
  components: {
    HeaderComp,
    FloatButton,
    FooterComp,
    AcessComp,
    VlibrasComp,
  },
};
</script>

<style>
#app {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-initial);
}

.font-h1 {
  font-size: 170%;
}

.font-h2 {
  font-size: 120%;
}

.font-h3 {
  font-size: 100%;
}

.font-h4 {
  font-size: 75%;
}
.font-h5 {
  font-size: 70%;
}

.font-h6 {
  font-size: 50%;
}

.bg-body {
  background-color: var(--bg-body);
}

.h-news2 {
  height: 560px !important;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

/* DEFINIÇÃO DAS CORES PELO ROOT PARA ALTERAR PELA ACESSIBILIDADE */

.bg-custom-blue1 {
  background: var(--bg-custom-blue1) !important;
}

.bg-custom-blue2 {
  background: var(--bg-custom-blue2) !important;
}

.bg-custom-orange {
  background: var(--bg-custom-orange) !important;
}

.bg-custom-gray {
  background: var(--bg-custom-gray) !important;
}

.bg-custom-yellow {
  background-color: var(--bg-custom-yellow) !important;
}

/* .contrast .bg-custom-blue1 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrast .bg-custom-blue2 {
  background: rgba(255, 255, 255, 0.2) !important;
}

.contrast .bg-custom-orange {
  background: rgba(255, 255, 255, 0.1) !important;
} */

.text-custom-blue1 {
  color: var(--text-custom-blue1);
}

.text-custom-blue2 {
  color: var(--text-custom-blue2);
}

.text-custom-orange {
  color: var(--text-custom-orange);
}

.text-custom-gray {
  color: var(--text-custom-gray);
}

.border-custom-blue1 {
  border-color: var(--border-custom-blue1);
}

.border-custom-blue2 {
  border-color: var(--border-custom-blue2);
}

.border-custom-orange {
  border-color: var(--border-custom-orange);
}

:root {
  --bg-body: #f2f2f2;

  --color-blue1: #05488d;
  --color-blue2: #16a6df;
  --color-orange: #ee7627;
  --color-gray: rgba(255, 255, 246, 1);
  --color-yellow: rgba(250, 204, 21, 1);

  --bg-custom-blue1: #05488d;
  --bg-custom-blue2: #16a6df;
  --bg-custom-orange: #ee7627;
  --bg-custom-gray: rgba(255, 255, 246, 1);
  --bg-custom-yellow: rgba(250, 204, 21, 1);

  --text-custom-blue1: #05488d;
  --text-custom-blue2: #16a6df;
  --text-custom-orange: #ee7627;
  --text-custom-gray: rgba(255, 255, 246, 1);

  --border-custom-blue1: #05488d;
  --border-custom-blue2: #16a6df;
  --border-custom-orange: #ee7627;
}

/* ESTILIZAR SCROLL */

.scrollWhite::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.scrollWhite::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2); /* color of the tracking area */
  border-radius: 20px;
}

.scrollWhite::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.8); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.scrollBlue::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.scrollBlue::-webkit-scrollbar-track {
  background: rgba(25, 84, 147, 0.2); /* color of the tracking area */
  border-radius: 20px;
}

.scrollBlue::-webkit-scrollbar-thumb {
  background-color: rgba(25, 84, 147, 0.8); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.scrollBlue2::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.scrollBlue2::-webkit-scrollbar-track {
  background: rgba(25, 84, 147, 0.2); /* color of the tracking area */
  border-radius: 20px;
}

.scrollBlue2::-webkit-scrollbar-thumb {
  background-color: #16a6df; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

/* ZOOM */

.zoom {
  overflow: hidden;
}

.zoom img {
  /* z-index: 0; */
  max-width: 100%;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  object-fit: cover;
}

.titleZoom {
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.zoom:hover img,
.zoom:hover .titleZoom {
  -moz-transform: scale(1.05) !important;
  -webkit-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}

/* FIM DO ZOOM */
</style>
