<template>
  <div class="px-6 pt-2 pb-1 rounded-b bg-gray-200">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
