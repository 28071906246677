<template>
  <footer
    id="footerId"
    class="bg-custom-blue1 mt-10 text-center lg:text-left text-white"
  >
    <div class="p-6 bg-gray-100 overflow-x-auto">
      <div
        class="container flex justify-center items-center lg:justify-between"
      >
        <div class="mr-12 hidden lg:block text-gray-600 font-bold">
          <span>Conecte-se conosco nas redes sociais:</span>
        </div>
        <div class="flex justify-center">
          <a
            href="https://www.facebook.com/prefeituraduquedecaxias"
            class="mr-5 social-footer"
            target="_blank"
            title="Clique para ir a Página do Facebook"
          >
            <i class="fa fa-facebook w-3.5" aria-hidden="true"></i>
          </a>
          <a
            href="https://twitter.com/duquedecaxiasof"
            class="mr-6 social-footer"
            target="_blank"
            title="Clique para ir a Página do Twitter"
          >
            <i class="fa fa-twitter w-3.5" aria-hidden="true"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCHwABpwYHHCylGzKzvg1_Hw"
            class="mr-6 social-footer"
            target="_blank"
            title="Clique para ir o Canal do Youtube"
          >
            <i class="fa fa-youtube-play w-3.5" aria-hidden="true"></i>
          </a>
          <a
            href="https://www.instagram.com/duquedecaxiasoficial/"
            class="mr-6 social-footer"
            target="_blank"
            title="Clique para ir a Página do Instagram"
          >
            <i class="fa fa-instagram w-3.5" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="container">
      <div>
        <img
          src="../assets/img2/logo.png"
          alt="Logo da Prefeitura de Duque de Caxias"
          title="Logo da Prefeitura de Duque de Caxias"
          class="mx-auto my-6 w-28 md:w-64"
        />
      </div>

      <div class="mx-6 lg:ml-28 py-10 text-center lg:text-left">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div class="">
            <h6 class="title-list mb-3">Conteúdos</h6>
            <p class="mb-6">
              <a href="https://transparencia.duquedecaxias.rj.gov.br/autarquias.php" class="items-portal"
                >Autarquias</a
              >
            </p>
            <p class="mb-6">
              <a
                href="https://www.portaltransparencia.gov.br/localidades/3301702-duque-de-caxias"
                class="items-portal"
                >Conv. Governo Federal</a
              >
            </p>
            <p class="mb-6">
              <a href="https://transparencia.duquedecaxias.rj.gov.br/covid19.php" class="items-portal"
                >COVID-19</a
              >
            </p>
            <p class="mb-6">
              <a href="https://transparencia.duquedecaxias.rj.gov.br/despesas.php" class="items-portal"
                >Despesas</a
              >
            </p>
            <p class="">
              <a href="https://transparencia.duquedecaxias.rj.gov.br/conteudos.php" class="items-portal"
                >Ver todos os conteúdos
                <i class="fa fa-long-arrow-right" aria-hidden="true"></i
              ></a>
            </p>
          </div>
          <div class="">
            <h6 class="title-list mb-3">Outros Portais</h6>
            <p class="mb-6">
              <a
                href="https://www.portaltransparencia.gov.br/"
                target="_blank"
                class="items-portal"
                >Governo Federal</a
              >
            </p>
            <p class="mb-6">
              <a
                href="https://www12.senado.leg.br/transparencia"
                target="_blank"
                class="items-portal"
                >Senado</a
              >
            </p>
            <p class="mb-6">
              <a
                href="https://www2.camara.leg.br/transparencia/.lixeira/a-transparencia-na-camara"
                target="_blank"
                class="items-portal"
                >Camâra Federal</a
              >
            </p>
            <p class="mb-6">
              <a
                href="https://www.rj.gov.br/web/informacaopublica/"
                target="_blank"
                class="items-portal"
                >Governo Estadual</a
              >
            </p>
            <p>
              <a
                href="https://www.cmdc.rj.gov.br/?page_id=7592"
                target="_blank"
                class="items-portal"
                >Leis Municipais</a
              >
            </p>
          </div>
          <div class="md:col-span-2 lg:col-span-1">
            <h6 class="title-list mb-4">Fale Conosco</h6>
            <p class="items-end scrollWhite mb-4">
              <i class="fa fa-phone pr-4" aria-hidden="true"></i>
              (21) 2773-6200
            </p>
            <p class="items-end scrollWhite mb-4">
              <i class="fa fa-envelope pr-4" aria-hidden="true"></i>
              ouvidoria@duquedecaxias.rj.gov.br
            </p>
            <p class="items-end scrollWhite mb-4">
              <i class="fa fa-map-marker pr-4" aria-hidden="true"></i>
              Alameda Esmeralda 206 <br />
              Jardim Primavera, Duque de <br />
              Caxias | RJ
            </p>
            <p class="items-end scrollWhite">
              <i class="fa fa-map-marker pr-4" aria-hidden="true"></i>
              Praça Roberto da Silveira, 31 - 2º <br />
              andar Centro, Duque de Caxias | RJ
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center p-6 bg-custom-blue2 font-h4">
      <div class="container">
        SEDE DA PREFEITURA MUNICIPAL DE DUQUE DE CAXIAS - ALAMEDA ESMERALDA 206,
        JD. PRIMAVERA, DUQUE DE CAXIAS / RJ <br />
        CEP: 25215-260 - TEL: (21) 2773-6200 CNPJ: 29.138.328/0001-50
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComp",
};
</script>

<style scoped>
footer {
  box-shadow: 10px -8px 30px rgb(0, 0, 0, 0.2);
}

.bg-footer1 {
  background-color: var(--bg-footer-blue1);
}

.bg-footer2 {
  background-color: var(--bg-footer-blue2);
}

ul.link-footer li a,
ul.link-footer li button,
p {
  font-size: 80% !important;
  font-weight: 600 !important;
}

input {
  background: rgba(0, 0, 0, 0.1) !important;
}

.title-list {
  @apply uppercase font-semibold flex justify-center lg:justify-start;
}

.items-end {
  @apply overflow-x-auto  flex items-center justify-center lg:justify-start text-center lg:text-left;
}

.items-portal {
  @apply text-gray-100 hover:opacity-80;
}

.social-footer {
  @apply text-gray-600 hover:opacity-60;
}
</style>
