<template>
  <div vw class="enabled">
    <div vw-access-button class="active"></div>
    <div vw-plugin-wrapper>
      <div class="vw-plugin-top-wrapper"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.vw-links .vw-tooltip__item {
  z-index: 99 !important;
}
</style>
