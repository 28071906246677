<template>
  <!-- ACESSIBILIDADE -->
  <div class="flex justify-center">
    <div>
      <div
        class="dropend fixed bottom-10 md:bottom-14 left-0 m-10 mb-0 ml-0 float-left text-white transition-all duration-300 ease-in-out"
        style="z-index: 999"
      >
        <button
          class="button-acess dropdown-toggle font-medium text-xs leading-tight uppercase hover:bg-blue-700 focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg active:text-white duration-100 ease-in-out flex items-center transition-all w-10 h-10 md:w-14 md:h-14 hover:shadow-sm shadow hover:w-40 bg-blue-900"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span class="acess-visible transition duration-300 ease-in-out"
            >Acessibilidade</span
          >

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            fill="currentColor"
            class="w-10 h-10 text-center m-2 hover:ml-2 md:ml-2"
          >
            <path
              d="M50 8.1c23.2 0 41.9 18.8 41.9 41.9 0 23.2-18.8 41.9-41.9 41.9C26.8 91.9 8.1 73.2 8.1 50S26.8 8.1 50 8.1M50 0C22.4 0 0 22.4 0 50s22.4 50 50 50 50-22.4 50-50S77.6 0 50 0zm0 11.3c-21.4 0-38.7 17.3-38.7 38.7S28.6 88.7 50 88.7 88.7 71.4 88.7 50 71.4 11.3 50 11.3zm0 8.9c4 0 7.3 3.2 7.3 7.3S54 34.7 50 34.7s-7.3-3.2-7.3-7.3 3.3-7.2 7.3-7.2zm23.7 19.7c-5.8 1.4-11.2 2.6-16.6 3.2.2 20.4 2.5 24.8 5 31.4.7 1.9-.2 4-2.1 4.7-1.9.7-4-.2-4.7-2.1-1.8-4.5-3.4-8.2-4.5-15.8h-2c-1 7.6-2.7 11.3-4.5 15.8-.7 1.9-2.8 2.8-4.7 2.1-1.9-.7-2.8-2.8-2.1-4.7 2.6-6.6 4.9-11 5-31.4-5.4-.6-10.8-1.8-16.6-3.2-1.7-.4-2.8-2.1-2.4-3.9.4-1.7 2.1-2.8 3.9-2.4 19.5 4.6 25.1 4.6 44.5 0 1.7-.4 3.5.7 3.9 2.4.7 1.8-.3 3.5-2.1 3.9z"
            ></path>
          </svg>
        </button>

        <div
          class="offcanvas fixed offcanvas-start bottom-0 flex flex-col max-w-full bg-white invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 border-none w-96 overflow-y-auto overflow-x-auto scrollWhite text-center"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div class="offcanvas-header flex items-center justify-between p-4">
            <h5
              class="offcanvas-title mb-0 leading-normal font-semibold text-black text-xs bottom-0"
              id="offcanvasRightLabel  "
            >
              Acessibilidade
            </h5>
            <button
              type="button"
              class="btn-close box-content w-4 h-4 p-2 text-black border-none rounded-full opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 close-acess"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                fill="currentColor"
              >
              </svg>
            </button>
          </div>
          <!-- AUMENTAR TEXTO -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="aumentarTextoId"
              class="transition-all inline-flex cursor-pointer"
              onclick="aumentar()"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M256 200v16c0 4.25-3.75 8-8 8h-56v56c0 4.25-3.75 8-8 8h-16c-4.25 0-8-3.75-8-8v-56h-56c-4.25 0-8-3.75-8-8v-16c0-4.25 3.75-8 8-8h56v-56c0-4.25 3.75-8 8-8h16c4.25 0 8 3.75 8 8v56h56c4.25 0 8 3.75 8 8zM288 208c0-61.75-50.25-112-112-112s-112 50.25-112 112 50.25 112 112 112 112-50.25 112-112zM416 416c0 17.75-14.25 32-32 32-8.5 0-16.75-3.5-22.5-9.5l-85.75-85.5c-29.25 20.25-64.25 31-99.75 31-97.25 0-176-78.75-176-176s78.75-176 176-176 176 78.75 176 176c0 35.5-10.75 70.5-31 99.75l85.75 85.75c5.75 5.75 9.25 14 9.25 22.5z"
                ></path></svg
              >&nbsp; Aumentar Texto
            </button>
          </div>
          <!-- DIMINUIR TEXTO -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="diminuirTextoId"
              class="transition-all inline-flex cursor-pointer"
              onclick="diminuir()"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M256 200v16c0 4.25-3.75 8-8 8h-144c-4.25 0-8-3.75-8-8v-16c0-4.25 3.75-8 8-8h144c4.25 0 8 3.75 8 8zM288 208c0-61.75-50.25-112-112-112s-112 50.25-112 112 50.25 112 112 112 112-50.25 112-112zM416 416c0 17.75-14.25 32-32 32-8.5 0-16.75-3.5-22.5-9.5l-85.75-85.5c-29.25 20.25-64.25 31-99.75 31-97.25 0-176-78.75-176-176s78.75-176 176-176 176 78.75 176 176c0 35.5-10.75 70.5-31 99.75l85.75 85.75c5.75 5.75 9.25 14 9.25 22.5z"
                ></path></svg
              >&nbsp; Diminuir Texto
            </button>
          </div>
          <!-- NORMALIZAR TEXTO -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="normalizarTextoId"
              class="transition-all inline-flex cursor-pointer"
              onclick="normalizar()"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M384 224c0 105.75-86.25 192-192 192-57.25 0-111.25-25.25-147.75-69.25-2.5-3.25-2.25-8 0.5-10.75l34.25-34.5c1.75-1.5 4-2.25 6.25-2.25 2.25 0.25 4.5 1.25 5.75 3 24.5 31.75 61.25 49.75 101 49.75 70.5 0 128-57.5 128-128s-57.5-128-128-128c-32.75 0-63.75 12.5-87 34.25l34.25 34.5c4.75 4.5 6 11.5 3.5 17.25-2.5 6-8.25 10-14.75 10h-112c-8.75 0-16-7.25-16-16v-112c0-6.5 4-12.25 10-14.75 5.75-2.5 12.75-1.25 17.25 3.5l32.5 32.25c35.25-33.25 83-53 132.25-53 105.75 0 192 86.25 192 192z"
                ></path></svg
              >&nbsp; Normalizar Texto
            </button>
          </div>
          <!-- ALTO CONTRASTE -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="AltoContrasteId"
              class="transition-all inline-flex cursor-pointer"
              accesskey="3"
              onclick="window.toggleContrast();"
              onkeydown="window.toggleContrast();"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M192 360v-272c-75 0-136 61-136 136s61 136 136 136zM384 224c0 106-86 192-192 192s-192-86-192-192 86-192 192-192 192 86 192 192z"
                ></path></svg
              >&nbsp; Alto Contraste
            </button>
          </div>
          <!-- CONTRASTE NEGATIVO -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="ContrasteNegativeId"
              class="transition-all inline-flex cursor-pointer"
              accesskey="3"
              onclick="window.toggleContrastNegative()"
              onkeydown="window.toggleContrastNegative()"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M416 240c-23.75-36.75-56.25-68.25-95.25-88.25 10 17 15.25 36.5 15.25 56.25 0 61.75-50.25 112-112 112s-112-50.25-112-112c0-19.75 5.25-39.25 15.25-56.25-39 20-71.5 51.5-95.25 88.25 42.75 66 111.75 112 192 112s149.25-46 192-112zM236 144c0-6.5-5.5-12-12-12-41.75 0-76 34.25-76 76 0 6.5 5.5 12 12 12s12-5.5 12-12c0-28.5 23.5-52 52-52 6.5 0 12-5.5 12-12zM448 240c0 6.25-2 12-5 17.25-46 75.75-130.25 126.75-219 126.75s-173-51.25-219-126.75c-3-5.25-5-11-5-17.25s2-12 5-17.25c46-75.5 130.25-126.75 219-126.75s173 51.25 219 126.75c3 5.25 5 11 5 17.25z"
                ></path></svg
              >&nbsp; Contraste Negativo
            </button>
          </div>
          <!-- NORMALIZAR CONTRASTE -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="NormalContrasteId"
              class="transition-all inline-flex cursor-pointer"
              accesskey="3"
              onclick="window.toggleContrastNormal();"
              onkeydown="window.toggleContrastNormal();"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill="currentColor"
                  d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                />
                <path
                  d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
                /></svg
              >&nbsp; Normalizar Contraste
            </button>
          </div>
          <!-- SUBLINHAR LINKS (E BUTTONS) -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="linkUnderlineId"
              class="transition-all inline-flex cursor-pointer"
              accesskey="3"
              onclick="window.toogleLinkUnderline()"
              onkeydown="window.toogleLinkUnderline()"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M364 304c0-6.5-2.5-12.5-7-17l-52-52c-4.5-4.5-10.75-7-17-7-7.25 0-13 2.75-18 8 8.25 8.25 18 15.25 18 28 0 13.25-10.75 24-24 24-12.75 0-19.75-9.75-28-18-5.25 5-8.25 10.75-8.25 18.25 0 6.25 2.5 12.5 7 17l51.5 51.75c4.5 4.5 10.75 6.75 17 6.75s12.5-2.25 17-6.5l36.75-36.5c4.5-4.5 7-10.5 7-16.75zM188.25 127.75c0-6.25-2.5-12.5-7-17l-51.5-51.75c-4.5-4.5-10.75-7-17-7s-12.5 2.5-17 6.75l-36.75 36.5c-4.5 4.5-7 10.5-7 16.75 0 6.5 2.5 12.5 7 17l52 52c4.5 4.5 10.75 6.75 17 6.75 7.25 0 13-2.5 18-7.75-8.25-8.25-18-15.25-18-28 0-13.25 10.75-24 24-24 12.75 0 19.75 9.75 28 18 5.25-5 8.25-10.75 8.25-18.25zM412 304c0 19-7.75 37.5-21.25 50.75l-36.75 36.5c-13.5 13.5-31.75 20.75-50.75 20.75-19.25 0-37.5-7.5-51-21.25l-51.5-51.75c-13.5-13.5-20.75-31.75-20.75-50.75 0-19.75 8-38.5 22-52.25l-22-22c-13.75 14-32.25 22-52 22-19 0-37.5-7.5-51-21l-52-52c-13.75-13.75-21-31.75-21-51 0-19 7.75-37.5 21.25-50.75l36.75-36.5c13.5-13.5 31.75-20.75 50.75-20.75 19.25 0 37.5 7.5 51 21.25l51.5 51.75c13.5 13.5 20.75 31.75 20.75 50.75 0 19.75-8 38.5-22 52.25l22 22c13.75-14 32.25-22 52-22 19 0 37.5 7.5 51 21l52 52c13.75 13.75 21 31.75 21 51z"
                ></path></svg
              >&nbsp; Sublinhar Links
            </button>
          </div>
          <!-- FONTE LEGIVEL (ARIAL) -->
          <div class="offcanvas-body flex-grow p-4">
            <button
              id="FontReadableId"
              class="transition-all inline-flex cursor-pointer"
              accesskey="4"
              onclick="window.toogleFontReadable()"
              onkeydown="window.toogleFontReadable()"
            >
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                viewBox="0 0 448 448"
              >
                <path
                  fill="currentColor"
                  d="M181.25 139.75l-42.5 112.5c24.75 0.25 49.5 1 74.25 1 4.75 0 9.5-0.25 14.25-0.5-13-38-28.25-76.75-46-113zM0 416l0.5-19.75c23.5-7.25 49-2.25 59.5-29.25l59.25-154 70-181h32c1 1.75 2 3.5 2.75 5.25l51.25 120c18.75 44.25 36 89 55 133 11.25 26 20 52.75 32.5 78.25 1.75 4 5.25 11.5 8.75 14.25 8.25 6.5 31.25 8 43 12.5 0.75 4.75 1.5 9.5 1.5 14.25 0 2.25-0.25 4.25-0.25 6.5-31.75 0-63.5-4-95.25-4-32.75 0-65.5 2.75-98.25 3.75 0-6.5 0.25-13 1-19.5l32.75-7c6.75-1.5 20-3.25 20-12.5 0-9-32.25-83.25-36.25-93.5l-112.5-0.5c-6.5 14.5-31.75 80-31.75 89.5 0 19.25 36.75 20 51 22 0.25 4.75 0.25 9.5 0.25 14.5 0 2.25-0.25 4.5-0.5 6.75-29 0-58.25-5-87.25-5-3.5 0-8.5 1.5-12 2-15.75 2.75-31.25 3.5-47 3.5z"
                ></path></svg
              >&nbsp; Fonte Legível
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIM DA ACESSIBILIDADE -->
</template>

<script></script>

<style>
body {
  overflow-y: auto !important;
  padding-right: 0px !important;
}

/* .offcanvas-backdrop {
  background-color: rgba(0, 0, 0, 0) !important;
} */

.contrastNegative,
.contrastNegative nav,
.contrastNegative div,
.contrastNegative li,
.contrastNegative ol,
.contrastNegative ul,
.contrastNegative header,
.contrastNegative footer,
.contrastNegative section,
.contrastNegative main,
.contrastNegative aside,
.contrastNegative article,
.contrastNegative .bg-white,
.contrastNegative a {
  background: black !important;
  color: yellow !important;
}

.contrastNegative div.divImportant {
  background: url("https://images.unsplash.com/photo-1558981806-ec527fa84c39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80") !important;
  background-position: center !important;
  background-size: cover !important;
  object-fit: fill;
  height: auto;
}

.contrastNegative h1,
.contrastNegative h2,
.contrastNegative h3,
.contrastNegative h4,
.contrastNegative h5,
.contrastNegative h6,
.contrastNegative p,
.contrastNegative label,
.contrastNegative strong,
.contrastNegative em,
.contrastNegative cite,
.contrastNegative q,
.contrastNegative i,
.contrastNegative b,
.contrastNegative u,
.contrastNegative span,
.contrastNegative .close-acess {
  color: yellow !important;
}

.contrastNegative #ContrasteNegativeId {
  color: blue;
}

.contrastNegative .active {
  background: #333 !important;
}

.contrastNegative .nav-link:hover {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrast,
.contrast nav,
.contrast div,
.contrast li,
.contrast ol,
.contrast ul,
.contrast header,
.contrast footer,
.contrast section,
.contrast main,
.contrast aside,
.contrast article,
.contrast .bg-white,
.contrast a {
  background: black !important;
  color: white !important;
}

.contrast .active {
  background: #333 !important;
}

.contrast .nav-link:hover {
  background: rgba(255, 255, 255, 0.4) !important;
}

.contrast h1,
.contrast h2,
.contrast h3,
.contrast h4,
.contrast h5,
.contrast h6,
.contrast p,
.contrast label,
.contrast strong,
.contrast em,
.contrast cite,
.contrast q,
.contrast i,
.contrast b,
.contrast u,
.contrast span,
.contrast .close-acess {
  color: white !important;
}

.contrastNormal,
.contrastNormal nav,
.contrastNormal div,
.contrastNormal li,
.contrastNormal ol,
.contrastNormal header,
.contrastNormal footer,
.contrastNormal section,
.contrastNormal main,
.contrastNormal aside,
.contrastNormal article {
  background: auto !important;
  color: auto !important;
}

.contrastNormal h1,
.contrastNormal h2,
.contrastNormal h3,
.contrastNormal h4,
.contrastNormal h5,
.contrastNormal h6,
.contrastNormal p,
.contrastNormal label,
.contrastNormal strong,
.contrastNormal em,
.contrastNormal cite,
.contrastNormal q,
.contrastNormal i,
.contrastNormal b,
.contrastNormal u,
.contrastNormal span,
.contrastNormal .close-acess {
  color: auto !important;
}

.contrast div.divImportant {
  background: url("https://images.unsplash.com/photo-1558981806-ec527fa84c39?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80") !important;
  background-position: center !important;
  background-size: cover !important;
  object-fit: fill;
  height: auto;
}

.contrast #AltoContrasteId {
  color: blue !important;
}

.linkUnderline a,
.linkUnderline button {
  text-decoration: underline !important;
}

.linkUnderline #linkUnderlineId {
  color: blue !important;
}

.fontReadable h1,
.fontReadable h2,
.fontReadable h3,
.fontReadable h4,
.fontReadable h5,
.fontReadable h6,
.fontReadable p,
.fontReadable label,
.fontReadable strong,
.fontReadable em,
.fontReadable cite,
.fontReadable q,
.fontReadable i,
.fontReadable b,
.fontReadable u,
.fontReadable span,
.fontReadable button,
.fontReadable a {
  font-family: Arial, Helvetica, sans-serif !important;
}

.fontReadable #FontReadableId {
  color: blue !important;
}

#diminuirTextoId:hover,
#aumentarTextoId:hover,
#normalizarTextoId:hover,
#AltoContrasteId:hover,
#ContrasteNegativeId:hover,
#NormalContrasteId:hover,
#linkUnderlineId:hover,
#FontReadableId:hover {
  color: blue !important;
}

.acess-visible {
  display: none;
  margin-left: 10px;
}

.button-acess:hover .acess-visible {
  display: inline;
}
</style>
