<template>
  <header class="top-0 z-40">
    <!-- NAVEGAÇÃO EM TELAS DE TAMANHO LG PARA CIMA -->
    <nav
      class="bg-gray-100 hidden lg:block fixed w-full"
      style="padding: 0px; z-index: 90"
    >
      <div class="grid grid-cols-10 container lg:text-xs my-0">
        <a href="#conteudo-1">
          <div class="menu-top nav-link">
            <p class="">
              Ir ao conteúdo
              <span class="bg-gray-500 px-2 rounded text-white">1</span>
            </p>
          </div>
        </a>
        <a href="#menuId">
          <div class="menu-top nav-link font-h3">
            <p class="">
              Ir para o menu
              <span class="bg-gray-500 px-2 rounded text-white">2</span>
            </p>
          </div>
        </a>
        <a href="#buscaId">
          <div class="menu-top nav-link font-h3">
            <p class="">
              Ir para a busca
              <span class="bg-gray-500 px-2 rounded text-white">3</span>
            </p>
          </div>
        </a>
        <a href="#footerId">
          <div class="menu-top nav-link font-h3">
            <p class="">
              Ir para o rodapé
              <span class="bg-gray-500 px-1.5 rounded text-white">4</span>
            </p>
          </div>
        </a>
        <div class="col-span-3">
          <div class="w-full py-3">&nbsp;&nbsp;</div>
        </div>

        
        <a
          href="https://transparencia.duquedecaxias.rj.gov.br/mapa-do-site.php"
        >
          <div class="menu-top nav-link font-h3">
            <span class="pl-1"
              ><i class="fa fa-sitemap" aria-hidden="true"></i
            ></span>
            Mapa do Site
          </div>
        </a>
        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/ouvidoria.php">
          <div class="menu-top nav-link font-h3">
            <span class="px-2"
              ><i class="fa fa-share" aria-hidden="true"></i></span
            >Ouvidoria
          </div>
        </a>
        <div>
          <div class="transition duration-300 py-1.5 mr-3">
            <div class="grid grid-cols-3">
              <a
                href="https://www.facebook.com/prefeituraduquedecaxias"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="menu-top-social">
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </div>
              </a>
              <a
                href="https://twitter.com/duquedecaxiasof"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="menu-top-social">
                  <i class="fa fa-twitter-square" aria-hidden="true"></i>
                </div>
              </a>
              <a
                href="https://www.instagram.com/duquedecaxiasoficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div class="menu-top-social">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- NAVEGAÇÃO MOBILE EM TELAS DE TAMANHO MENOR QUE LG -->
    <nav class="bg-gray-100 block lg:hidden" style="padding: 0px">
      <div class="flex justify-center container text-gray-600 text-xs my-0">
        <a
          href="https://transparencia.duquedecaxias.rj.gov.br/mapa-do-site.php"
        >
          <div class="transition duration-300 w-full my-2">
            <span class="px-2"
              ><i class="fa fa-sitemap" aria-hidden="true"></i
            ></span>
            Mapa do Site
          </div>
        </a>

        <a>
          <div class="transition duration-300 w-full mt-2 sm:px-10">
            <span class="px-2">&nbsp;&nbsp;</span>
          </div>
        </a>

        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/ouvidoria.php">
          <div class="transition duration-300 w-full my-2">
            <span class="px-2"
              ><i class="fa fa-share" aria-hidden="true"></i></span
            >Ouvidoria
          </div>
        </a>
      </div>
    </nav>

    <span id="buscaId"></span>

    <!-- MENU PRINCIPAL -->
    <nav
      class="relative w-full flex flex-wrap items-center justify-between bg-custom-blue1 text-gray-200 shadow-lg navbar navbar-expand-lg navbar-light nav-head"
    >
      <div
        class="container w-full flex flex-wrap items-center justify-between px-6"
      >
        <a href="/" class="link text-white pr-2 font-semibold mx-0"
          ><img
            src="../assets/img2/logo.png"
            title="Logo da Prefeitura Municipal de Duque de Caxias - Clique para ir a Página Inicial"
            style="width: 30vw; margin-left: -3vw"
            alt="Logo da Prefeitura Municipal de Duque de Caxias"
        /></a>
        <div
          class="collapse navbar-collapse items-center"
          id="navbarSupportedContent1"
        ></div>

        <button
          class="lg:hidden inline-block py-2 px-3 rounded-xl bg-white font-medium text-xs leading-tight uppercase transition duration-150 ease-in-out text-custom-blue1"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="bars"
            class="w-6"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </button>

        <!-- Right elements -->

        <div class="hidden lg:flex justify-center mt-6">
          <div class="mb-3 lg:w-96">
            <div
              class="input-group relative flex flex-wrap items-stretch w-full mb-4"
            >
              <input
                type="search"
                class="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                placeholder="O que você procura?"
                aria-label="O que você procura?"
                aria-describedby="button-addon2"
              />
              <button
                class="btn inline-block px-6 py-2.5 bg-custom-blue2 border border-white text-white hover:opacity-80 active:opacity-60 rounded-r active:bg-sky-700"
                type="button"
                id="button-addon2"
                title="Clique aqui para buscar o conteúdo"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  class="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <span id="menuId"></span>

      <!-- Right elements -->

      <!-- MENU RESPONSIVE -->
      <div
        class="navbar-toggler offcanvas offcanvas-start fixed bottom-0 flex flex-col max-w-full bg-custom-blue1 invisible bg-clip-padding shadow-sm outline-none transition duration-300 ease-in-out text-gray-700 top-0 left-0 border-none w-96 overflow-auto"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header flex items-center justify-between p-4">
          <h5
            class="offcanvas-title mb-0 leading-normal font-semibold text-white"
            id="offcanvasExampleLabel"
          >
            <a href="/">
              <img src="../assets/img/logo.png" alt="" style="width: 30vw" />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close box-content w-4 h-4 p-2 -my-5 -mr-2 text-white border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-gray-300 hover:opacity-75 hover:no-underline"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="offcanvas-body flex-grow overflow-y-auto">
          <ul class="relative">
            <li class="relative">
              <a href="/" class="menu-responsive"
                >Página Inicial</a
              >
            </li>

            <hr class="text-white" />
            <li class="relative">
              <a href="https://transparencia.duquedecaxias.rj.gov.br/atendimento.php" class="menu-responsive"
                >Atendimento</a
              >
            </li>

            <hr class="text-white" />
            <li class="relative">
              <a
                href="http://duquedecaxias.rj.gov.br/images/organograma/ORGANOGRAMA_2022.pdf"
                target="_blank"
                class="menu-responsive"
                >Organograma</a
              >
            </li>
            <hr class="text-white" />
            <li class="relative">
              <a
                href="https://duquedecaxias.rj.gov.br/portal/boletim-oficial.html"
                target="_blank"
                class="menu-responsive"
                >Boletim Oficial</a
              >
            </li>

            <hr class="text-white" />
            <li class="relative">
              <a class="nav-item dropdown static">
                <a
                  class="nav-link menu-responsive"
                  href="#"
                  type="button"
                  id="dropdownMenuButtonX"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  >Conteúdos
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    class="w-2 ml-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </a>
                <div
                  class="dropdown-menu text-left w-full mt-0 hidden shadow-lg bg-white absolute left-0 top-full"
                  aria-labelledby="dropdownMenuButtonX"
                >
                  <div class="px-6 lg:px-8 py-5">
                    <div class="grid grid-cols-1 gap-6">
                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/autarquias.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Autarquias</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://www.portaltransparencia.gov.br/localidades/3301702-duque-de-caxias"
                          target="_blank"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Conv. Governo Federal</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/covid19.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >COVID-19</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/despesas.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Despesas</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/divida-ativa.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Dívida Ativa</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/escolas-municipais.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Escolas Municipais</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/estatisticas-de-acesso.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Estatísticas de Acesso</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/fundos-municipais.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Fundos Municipais</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/gastos-diarias.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Gastos com Diárias</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/normativos.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Leis de Acesso a Informação</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/licitacoes.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Licitações</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/obras-publicas.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Obras Públicas</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/orcamento.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Orçamento</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/ouvidoria.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Ouvidoria</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/patrimonios.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Patrimônios</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://www.cmdc.rj.gov.br/?page_id=7592"
                          aria-current="true"
                          target="_blank"
                          class="menu-items-dropdown"
                          >Pesquisas de Leis</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/plano-plurianual.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Plano Plurianual</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/prestacao-de-contas.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Prestação de Contas</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/receitas.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Receitas</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/recursos-financeiros.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Recursos Financeiros</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/relatorios-lrf.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Relatórios-LRF</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="http://transparencia.duquedecaxias.rj.gov.br/remuneracao-servidores.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Rem. dos Servidores</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://drive.google.com/file/d/1Ik6ooxwRoQGxl67TRLGeysnqj2pLjoS7/view"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >SIAFIC - DECRETO FEDERAL 10.540/20</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="/conteudos/termos-contratos"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Termos Contratos</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/unidades-saude.php"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Unidades de Saúde</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="http://pmdc.modernizacao.com.br:8151/ctp/form.jsp?sys=CTP&action=openform&formID=464569857&align=0&mode=-1&goto=-1&filter=&scrolling=no"
                          target="_blank"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Ordem Cronológica</a
                        >
                      </div>

                      <div class="menu-bg-text">
                        <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/conteudos.php"
                          aria-current="true"
                          class="block px-6 py-6 w-full bg-custom-blue2 text-custom-gray gray-400 m-0 hover:opacity-90 hover:underline transition duration-150 ease-in-out rounded-lg"
                          >Todos os conteúdos
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <hr class="text-white" />
            <li class="relative">
              <a class="nav-item dropdown static">
                <a
                  class="nav-link menu-responsive"
                  href="#"
                  type="button"
                  id="dropdownMenuButtonX"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  >Secretarias
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    class="w-2 ml-2"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </a>
                <div
                  class="dropdown-menu text-left w-full mt-0 hidden shadow-lg bg-white absolute left-0 top-full"
                  aria-labelledby="dropdownMenuButtonX"
                >
                  <div class="px-6 lg:px-8 py-5">
                    <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/administracao-/6328"
                          class="menu-items-dropdown"
                          >Administração</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/agricultura/6321"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Agricultura</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/assistencia-social-e-direitos-humanos/6325"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Assistência Social e Direitos Humanos</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/ciencia-e-tecnologia-/6391"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Ciência e Tecnologia</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/comunicacao-social/6326"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Comunicação Social</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/controle-interno/6346"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Controle Interno</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/cultura-e-turismo/6323"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Cultura e Turismo</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/educacao/6315"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Educação</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/esporte-e-lazer/6318"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Esporte e Lazer</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/eventos-/6390"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Eventos</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/fazenda/6331"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Fazenda</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/governo/6329"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Governo</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/habitacao/6389"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Habitação</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/saude-/6324"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Saúde</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/obras-e-defesa-civil-/6314"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Obras e Defesa Civil</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/procuradoria-geral-do-municipio-/6330"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Procuradoria Geral do Município</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/transportes-e-servicos-publicos/6327"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Transportes e Serviços Públicos</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/urbanismo-/6338"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Urbanismo</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/trabalho,-emprego-e-renda/6317"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Trabalho, Emprego e Renda</a
                        >
                      </div>
                      <div class="menu-bg-text">
                        <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/seguranca-publica-/6320"
                          aria-current="true"
                          class="menu-items-dropdown"
                          >Segurança Pública</a
                        >
                      </div>
                    </div>
                    <br />
                    <div class="menu-bg-text">
                      <a
                        href="https://duquedecaxias.rj.gov.br/secretaria/meio-ambiente-e-protecao-animal-/6316"
                        aria-current="true"
                        class="menu-items-dropdown"
                        >Meio Ambiente e Proteção Animal</a
                      >
                    </div>
                    <br />
                    <div class="menu-bg-text">
                      <a
                        href="https://duquedecaxias.rj.gov.br/secretaria/secretaria-municipalde-defesa-dos-direitos-da-terceira-idade-/6392"
                        aria-current="true"
                        class="menu-items-dropdown"
                        >Secretaria Municipal de Defesa dos Direitos da Terceira
                        Idade</a
                      >
                    </div>
                    <br />
                    <div
                      class="col-span-1 lg:col-span-4 bg-white text-gray-600"
                    >
                      <a
                        href="https://duquedecaxias.rj.gov.br/secretarias"
                        aria-current="true"
                        class="block px-6 py-6 w-full bg-custom-blue2 text-custom-gray gray-400 m-0 hover:opacity-90 hover:underline transition duration-150 ease-in-out rounded-lg"
                        >Todas as secretarias
                        <i class="fa fa-long-arrow-right" aria-hidden="true"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </a>
            </li>
            <hr class="text-white" />
            <li class="relative">
              <a
                href="https://transparencia.duquedecaxias.rj.gov.br/balancos.php"
                class="menu-responsive"
                >Balanços</a
              >
            </li>
            <hr class="text-white" />
            <li class="relative">
              <a
                href="https://duquedecaxias.rj.gov.br/noticias/1"
                target="_blank"
                class="menu-responsive"
                >Notícias</a
              >
            </li>
          </ul>

          <div class="flex justify-center py-6 w-full">
            <a
              href="#!"
              type="button"
              class="menu-responsive-social hover:bg-blue-600 hover:border-blue-600"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-f"
                class="w-2 h-full mx-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>

            <a
              href="#!"
              type="button"
              class="menu-responsive-social hover:bg-sky-400 hover:border-sky-400"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                class="w-3 h-full mx-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                ></path>
              </svg>
            </a>

            <!-- <a
              href="#!"
              type="button"
              class="rounded-full border-2 border-gray-100 text-gray-100 leading-normal uppercase hover:bg-green-500 hover:border-green-500 hover:text-white focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="google"
                class="w-3 h-full mx-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 488 512"
              >
                <path
                  fill="currentColor"
                  d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                ></path>
              </svg>
            </a> -->

            <a
              href="#!"
              type="button"
              class="menu-responsive-social hover:bg-purple-400 hover:border-purple-400"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                class="w-3 h-full mx-auto"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <!-- FIM DO MENU RESPONSIVE -->
    </nav>

    <!-- NAVEGAÇÃO EM TELAS DE TAMANHO LG PARA CIMA -->
    <nav class="bg-custom-blue2 hidden lg:block" style="padding: 0px">
      <div
        class="grid grid-cols-7 lg:gap-1 xl:gap-4 container text-white font-bold uppercase lg:text-sm my-0"
      >
        <a href="https://transparencia.duquedecaxias.rj.gov.br/atendimento.php">
          <div class="menu-blue2 nav-link">Atendimento</div>
        </a>
        <a class="nav-item dropdown static">
          <a
            class="nav-link pr-2 lg:px-2 py-2 text-white  text-center menu-blue2  h-full transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap justify-center"
            href="#"
            type="button"
            id="dropdownMenuButtonX"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Conteúdos
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="caret-down"
              class="w-2 ml-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
              ></path>
            </svg>
          </a>
          <div
            class="dropdown-menu w-full mt-0 hidden shadow-lg bg-white absolute left-0 top-full"
            aria-labelledby="dropdownMenuButtonX"
          >
            <div class="px-6 lg:px-8 py-5">
              <div class="grid grid-cols-4 gap-6">
                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/autarquias.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Autarquias</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://www.portaltransparencia.gov.br/localidades/3301702-duque-de-caxias"
                    target="_blank"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Conv. Governo Federal</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/covid19.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >COVID-19</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/despesas.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Despesas</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/divida-ativa.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Dívida Ativa</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/escolas-municipais.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Escolas Municipais</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/estatisticas-de-acesso.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Estatísticas de Acesso</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/fundos-municipais.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Fundos Municipais</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/gastos-diarias.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Gastos com Diárias</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/normativos.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Leis de Acesso a Informação</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/licitacoes.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Licitações</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/obras-publicas.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Obras Públicas</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/orcamento.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Orçamento</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/ouvidoria.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Ouvidoria</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/patrimonios.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Patrimônios</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://www.cmdc.rj.gov.br/?page_id=7592"
                    target="_blank"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Pesquisas de Leis</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/plano-plurianual.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Plano Plurianual</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/prestacao-de-contas.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Prestação de Contas</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="https://transparencia.duquedecaxias.rj.gov.br/receitas.php"
                    class="menu-items-dropdown"
                    >Receitas</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/recursos-financeiros.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Recursos Financeiros</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/relatorios-lrf.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Relatórios-LRF</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                          href="http://transparencia.duquedecaxias.rj.gov.br/remuneracao-servidores.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Rem. dos Servidores</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://drive.google.com/file/d/1Ik6ooxwRoQGxl67TRLGeysnqj2pLjoS7/view"
                    aria-current="true"
                    target="_blank"
                    class="menu-items-dropdown"
                    >SIAFIC - DECRETO FEDERAL 10.540/20</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/termos-contratos.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Termos Contratos</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/unidades-saude.php"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Unidades de Saúde</a
                  >
                </div>

                <div class="menu-bg-text">
                  <a
                    href="http://pmdc.modernizacao.com.br:8151/ctp/form.jsp?sys=CTP&action=openform&formID=464569857&align=0&mode=-1&goto=-1&filter=&scrolling=no"
                    target="_blank"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Ordem Cronológica</a
                  >
                </div>

                <div class="col-span-1 lg:col-span-4 bg-white text-gray-600">
                  <a
                    href="https://transparencia.duquedecaxias.rj.gov.br/conteudos.php"
                    aria-current="true"
                    class="block px-6 py-6 w-full bg-custom-blue2 text-custom-gray gray-400 m-0 hover:opacity-90 hover:underline transition duration-150 ease-in-out rounded-lg"
                    >Todos os conteúdos
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a class="nav-item dropdown static">
          <a
            class="nav-link pr-2 lg:px-2 py-2 text-white text-center menu-blue2 h-full transition duration-150 ease-in-out dropdown-toggle flex items-center whitespace-nowrap justify-center"
            href="#"
            type="button"
            id="dropdownMenuButtonX"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >Secretarias
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="caret-down"
              class="w-2 ml-2"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
            >
              <path
                fill="currentColor"
                d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
              ></path>
            </svg>
          </a>
          <div
            class="dropdown-menu w-full mt-0 hidden shadow-lg bg-white absolute left-0 top-full"
            aria-labelledby="dropdownMenuButtonX"
          >
            <div class="px-6 lg:px-8 py-5">
              <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div class="menu-bg-text">
                  <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/administracao-/6328"
                    class="menu-items-dropdown"
                    >Administração</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/agricultura/6321"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Agricultura</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/assistencia-social-e-direitos-humanos/6325"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Assistência Social e Direitos Humanos</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/ciencia-e-tecnologia-/6391"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Ciência e Tecnologia</a
                  >
                </div>
              </div>
              <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div class="menu-bg-text">
                  <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/comunicacao-social/6326"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Comunicação Social</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                          href="https://duquedecaxias.rj.gov.br/secretaria/controle-interno/6346"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Controle Interno</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/cultura-e-turismo/6323"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Cultura e Turismo</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/educacao/6315"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Educação</a
                  >
                </div>
              </div>
              <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/esporte-e-lazer/6318"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Esporte e Lazer</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/eventos-/6390"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Eventos</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/fazenda/6331"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Fazenda</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/governo/6329"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Governo</a
                  >
                </div>
              </div>
              <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/habitacao/6389"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Habitação</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/saude-/6324"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Saúde</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/obras-e-defesa-civil-/6314"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Obras e Defesa Civil</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/procuradoria-geral-do-municipio-/6330"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Procuradoria Geral do Município</a
                  >
                </div>
              </div>
              <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/transportes-e-servicos-publicos/6327"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Transportes e Serviços Públicos</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/urbanismo-/6338"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Urbanismo</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/trabalho,-emprego-e-renda/6317"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Trabalho, Emprego e Renda</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/seguranca-publica-/6320"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Segurança Pública</a
                  >
                </div>
              </div>
              <br />
              <div class="grid grid-cols-2 gap-4">
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/meio-ambiente-e-protecao-animal-/6316"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Meio Ambiente e Proteção Animal</a
                  >
                </div>
                <div class="menu-bg-text">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretaria/secretaria-municipalde-defesa-dos-direitos-da-terceira-idade-/6392"
                    aria-current="true"
                    class="menu-items-dropdown"
                    >Secretaria Municipal de Defesa dos Direitos da Terceira
                    Idade</a
                  >
                </div>
                <div class="col-span-1 lg:col-span-4 bg-white text-gray-600">
                  <a
                    href="https://duquedecaxias.rj.gov.br/secretarias"
                    aria-current="true"
                    class="block px-6 py-6 w-full bg-custom-blue2 text-custom-gray gray-400 m-0 hover:opacity-90 hover:underline transition duration-150 ease-in-out rounded-lg"
                    >Todas as secretarias
                    <i class="fa fa-long-arrow-right" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </a>
        <a
          href="http://duquedecaxias.rj.gov.br/images/organograma/ORGANOGRAMA_2022.pdf"
          target="_blank"
        >
          <div class="menu-blue2 nav-link">Organograma</div>
        </a>
        <a
          href="https://duquedecaxias.rj.gov.br/portal/boletim-oficial.html"
          target="_blank"
        >
          <div class="menu-blue2 nav-link">Boletim Oficial</div>
        </a>
        <a
          href="https://transparencia.duquedecaxias.rj.gov.br/balancos.php"
          target="_blank"
        >
          <div class="menu-blue2 nav-link">Balanços</div>
        </a>
        <a href="https://duquedecaxias.rj.gov.br/noticias/1" target="_blank">
          <div class="menu-blue2 nav-link">Notícias</div>
        </a>
      </div>
    </nav>

    <div class="block lg:hidden btn-search-head">
      <div action="!#" class="md:float-right mt-3 text-gray-400">
        <div class="table relative w-full border-separate">
          <input
            type="text"
            name="s"
            class="btn-search"
            placeholder="Buscar no Portal"
          />
          <span class="table-cell relative align-middle whitespace-no-wrap">
            <button class="btn-search-secondary" type="submit">
              <i class="fa fa-search"></i>
            </button>
          </span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderComp",
};
</script>

<style scoped>
nav {
  padding: 30px;
}

.nav-head {
  padding: 10px;
  padding-top: 50px;
}

@media (max-width: 1024px) {
  .nav-head {
    padding: 10px;
    padding-top: auto;
  }
}

a.menu {
  font-weight: bold;
  color: #cecece;
}

.item_destaque {
  position: relative;
  overflow: hidden;
  display: block;
}
.item_destaque {
  height: auto;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px !important;
  }
}

.btn-search-head {
  @apply pb-0 md:pb-10 md:pr-20 px-7 max-w-screen-2xl;
}

.btn-search {
  @apply py-1 px-5 m-0 w-full text-gray-700 bg-white dark:bg-black bg-none 
  rounded border border-gray-400 border-solid box-border rounded-r-none;
}

.btn-search-secondary {
  @apply py-1 px-4 -ml-px bg-white dark:bg-black rounded border border-gray-400 border-solid cursor-pointer 
  hover:bg-gray-200 hover:text-gray-400 text-gray-400 rounded-l-none;
}

.menu-top {
  @apply transition duration-300 hover:bg-gray-500 hover:text-white w-full py-3;
}

.menu-top-social {
  @apply transition duration-150 text-gray-600 hover:opacity-70 text-lg;
}

.menu-responsive {
  @apply flex items-center text-lg py-4 px-6 h-12 overflow-hidden text-white text-ellipsis whitespace-nowrap hover:text-gray-900 bg-sky-400 hover:bg-gray-100 transition duration-300 ease-in-out;
}

.menu-responsive-social {
  @apply rounded-full border-2 border-gray-100 text-gray-100 leading-normal uppercase hover:text-white focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1;
}

.menu-blue2 {
  @apply transition duration-300 hover:bg-white hover:text-blue-600 w-full py-3;
}

.menu-bg-text {
  @apply bg-white text-gray-600;
}

.menu-items-dropdown {
  @apply block px-6 py-2 border-b border-gray-200 w-full hover:bg-gray-50 hover:text-gray-700 transition duration-150 ease-in-out;
}
</style>
